import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { useIsMobile } from "../../hooks";

const SpoilerAlert = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, threshold: 0.2 });
  const isMobile = useIsMobile(769);

  return (
    <div className={`spoiler-alert ${isInView ? "in-view" : ""}`}>
      <div className="container">
        <div className="spoiler-alert__header">
          <motion.h3
            className="spoiler-alert__title"
            initial={{ opacity: 0 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.1 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            Nothing is as essential as the air we breathe.
          </motion.h3>
          <div className={`spoiler-alert__description`} ref={ref}>
            <p>
              <strong>Spoiler Alert: </strong>
              {isMobile ? (
                <>
                  <span>Most homes are </span>
                  <span>just big boxes full of crappy air.</span>
                </>
              ) : (
                <>
                  <span>Most homes are just big boxes</span>{" "}
                  <span>full of crappy air.</span>
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpoilerAlert;
